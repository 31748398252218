<template>
    <Results
      :sourceAPI="cstmSourceAPI"
    />
</template>

<script>
import Results from './Results';
import resultMixin from '../mixins/results';
export default {
  name: "cstmResults",
  mixins: [resultMixin],
  components: {
    Results
  },
};
</script>